import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FeatureImage,
  FeatureImageHero,
  FeatureImageHeroMobile,
  H2,
  Paragraph,
  TwoColumnContainer,
  Span
} from "../components/styled/components"
import Fade from "react-reveal/Fade"
import { useMediaQuery } from "react-responsive"

const NewsPage = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <Layout>
      <SEO title="News" />

      {isTabletOrMobile ?
      <FeatureImageHeroMobile>
        <H2 fontSize="40px" textAlign="center">
          {data.contentfulPage.heroHeader}
        </H2>
        <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
      </FeatureImageHeroMobile> :
      <FeatureImageHero>
        <section>
          <h2>
            {data.contentfulPage.heroHeader}
          </h2>
          <Paragraph>
            {data.contentfulPage.heroDescription.heroDescription}
          </Paragraph>
        </section>
        <FeatureImage>
          <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
        </FeatureImage>
      </FeatureImageHero>}

      {data.contentfulPage.section1 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section1.sectionHeader}</H2>
            <Span>{data.contentfulPage.section1.sectionDate}</Span>
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section1.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <div>
            <Img fluid={data.contentfulPage.section1.sectionImage.fluid} alt={data.contentfulPage.section1.sectionImage.title} />
            {data.contentfulPage.section1.sectionLink &&
            <Link to={data.contentfulPage.section1.sectionLink}>
              View more details
            </Link>}
          </div>
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section2 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section2.sectionHeader}</H2>
            <Span>{data.contentfulPage.section2.sectionDate}</Span>
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section2.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <div>
            <Img fluid={data.contentfulPage.section2.sectionImage.fluid} alt={data.contentfulPage.section2.sectionImage.title} />
            {data.contentfulPage.section2.sectionLink &&
            <Link to={data.contentfulPage.section2.sectionLink}>
              View more details
            </Link>}
          </div>
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section3 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section3.sectionHeader}</H2>
            <Span>{data.contentfulPage.section3.sectionDate}</Span>
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section3.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <div>
            <Img fluid={data.contentfulPage.section3.sectionImage.fluid} alt={data.contentfulPage.section3.sectionImage.title} />
            {data.contentfulPage.section3.sectionLink &&
            <Link to={data.contentfulPage.section3.sectionLink}>
              View more details
            </Link>}
          </div>
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section4 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section4.sectionHeader}</H2>
            <Span>{data.contentfulPage.section4.sectionDate}</Span>
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section4.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <div>
            <Img fluid={data.contentfulPage.section4.sectionImage.fluid} alt={data.contentfulPage.section4.sectionImage.title} />
            {data.contentfulPage.section4.sectionLink &&
            <Link to={data.contentfulPage.section4.sectionLink}>
              View more details
            </Link>}
          </div>
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section5 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section5.sectionHeader}</H2>
            <Span>{data.contentfulPage.section5.sectionDate}</Span>
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section5.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <div>
            <Img fluid={data.contentfulPage.section5.sectionImage.fluid} alt={data.contentfulPage.section5.sectionImage.title} />
            {data.contentfulPage.section5.sectionLink &&
            <Link to={data.contentfulPage.section5.sectionLink}>
              View more details
            </Link>}
          </div>
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section6 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section6.sectionHeader}</H2>
            <Span>{data.contentfulPage.section6.sectionDate}</Span>
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section6.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <div>
            <Img fluid={data.contentfulPage.section6.sectionImage.fluid} alt={data.contentfulPage.section6.sectionImage.title} />
            {data.contentfulPage.section6.sectionLink &&
            <Link to={data.contentfulPage.section6.sectionLink}>
              View more details
            </Link>}
          </div>
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section7 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section7.sectionHeader}</H2>
            {data.contentfulPage.section7.sectionLink &&
            <Link to={data.contentfulPage.section7.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section7.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section7.sectionImage.fluid} alt={data.contentfulPage.section7.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section8 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section8.sectionHeader}</H2>
            {data.contentfulPage.section8.sectionLink &&
            <Link to={data.contentfulPage.section8.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section8.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section8.sectionImage.fluid} alt={data.contentfulPage.section8.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section9 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section9.sectionHeader}</H2>
            {data.contentfulPage.section9.sectionLink &&
            <Link to={data.contentfulPage.section9.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section9.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section9.sectionImage.fluid} alt={data.contentfulPage.section9.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section10 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section10.sectionHeader}</H2>
            {data.contentfulPage.section10.sectionLink &&
            <Link to={data.contentfulPage.section10.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section10.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section10.sectionImage.fluid} alt={data.contentfulPage.section10.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query NewsPageQuery {
    contentfulPage(pageName: {eq: "News"}) {
      heroHeader
      heroDescription {
        heroDescription
      }
      heroImage {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      section1 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
        sectionDate
      }
      section2 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
        sectionDate
      }
      section3 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
        sectionDate
      }
      section4 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
        sectionDate
      }
      section5 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
        sectionDate
      }
      section6 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
        sectionDate
      }
    }
  }
`
